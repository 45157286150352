<template>
  <validation-observer ref="simpleRules">
    <div>
      <div class="card">
        <div class="d-lg-flex justify-content-between align-items-center px-2 py-1">
          <h3 class="font-weight-bolder">
            {{ $t('faq') }}
          </h3>

          <div class="d-flex">
            <div class="income-order-search order-search w-100">
              <div class="anan-input search-input">
                <div class="anan-input__inner anan-input__inner--normal">
                  <input
                    v-model="search"
                    type="text"
                    :placeholder="$t('search')"
                    class="anan-input__input"
                    @change="getData()"
                  >
                  <div class="anan-input__suffix">
                    <i class="anan-input__clear-btn anan-icon" />
                    <i class="anan-input__suffix-icon anan-icon">
                      <i class="fal fa-search" />
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="anan-input__suffix">
          <router-link :to="{ name: 'admin-faq-add' }">
            <button class="btn btn-primary ml-2">
              <i class="far fa-plus mr-25" /> {{ $t('addQuestion') }}
            </button>
          </router-link>
        </div>
        <b-overlay
          :show="showOver"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="pl-2 pr-2 pt-1">
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="items"
              :fields="fields"
              show-empty
            >
              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>

              <template #cell(number)="data">
                {{ items.length - (perPage * (currentPage - 1) + (data.index + 1)) + 1 }}
              </template>
              <template #cell(status)="data">
                <div>
                  <b-form-checkbox
                    v-model="data.item.active"
                    class="custom-control-success"
                    switch
                    @change="Checkeditem(data.item)"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </div>
              </template>
              <template #cell(answer)="data">
                <span v-html="data.item.result" />
              </template>

              <template #cell(action)="data">
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-1 text-primary cursor-pointer"
                  @click="Edititem(data.item),stat = 'แก้ไขคำถามที่พบบ่อย'"
                />
                <feather-icon
                  class="mr-1 text-danger cursor-pointer"
                  icon="TrashIcon"
                  @click="Deleteitem(data.item)"
                />
              </template>
            </b-table>

            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="getData()"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>

    <!-- <b-modal
      id="modal-question-edit"
      centered
      :title="stat"
      ok-title="ตกลง"
      cancel-title="ยกเลิก"
      @ok.prevent="Submit()"
    >

    </b-modal> -->

  </validation-observer>
</template>

<script>
import {
  BTable,
  // BModal,
  // BFormGroup,
  // BFormInput,
  // BFormTextarea,
  BPagination,
  BFormCheckbox,
  // BDropdown,
  BOverlay,
} from 'bootstrap-vue'
import {
  // ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BTable,
    // BModal,
    // BFormGroup,
    // BFormInput,
    // BDropdown,
    ValidationObserver,
    BPagination,
    BFormCheckbox,
    // ValidationProvider,
    BOverlay,
    // quillEditor,
    // VEmojiPicker,
    // BFormTextarea,
  },
  data() {
    return {
      required,
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      question: null,
      answer: null,
      // dialogHidden: null,
      // stat: '',
      // editorOption: {
      //   modules: {
      //     toolbar: '#toolbar',
      //   },
      //   placeholder: 'กรอกข้อความ...',
      // },
      // items: [
      //   {
      //     number: 1, question: '1+1=?', answer: 'ตอบ 2', status: true,
      //   },
      //   {
      //     number: 2, question: '2+2=?', answer: 'ตอบ 4', status: false,
      //   },
      //   {
      //     number: 3, question: '1+5=?', answer: 'ตอบ 6', status: false,
      //   },
      // ],
      showOver: false,
      active: false,
      id: null,
      search: null,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'number', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'question', label: this.$t('question'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'answer', label: this.$t('answer'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'status', label: this.$t('inUse'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'action', label: this.$t('manage'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.showOver = true
      try {
        const params = {
          search: this.search,
          page: this.currentPage,
          perPage: this.perPage,
        }
        const { data: res } = await this.$http.get('/Question/Store/getData', { params })
        this.items = res.data
        this.totalRows = res.total
        // console.log(res)
        this.showOver = false
      } catch (err) {
        console.log(err)
        this.showOver = false
      }
    },
    Submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const obj = {
            id: this.id,
            question: this.question,
            result: this.answer,
            active: this.active,
          }
          this.$http.post('/Question/Store/Update', obj)
            .then(() => {
              this.Success('บันทึกข้อมูลเรียบร้อย')
              this.getData()
              this.$bvModal.hide('modal-question-edit')
            })
            .catch(err => {
              console.log(err)
              this.SwalError(err)
            })
          // alert(this.answer)
        }
      })
    },
    Edititem(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: 'admin-faq-edit', params: { id: data._id } })
      // if (data) {
      //   // eslint-disable-next-line no-underscore-dangle
      //   this.id = data._id
      //   this.question = data.question
      //   this.answer = data.result
      //   this.active = data.active
      // } else {
      //   this.id = null
      //   this.question = null
      //   this.answer = null
      //   this.active = null
      // }
      // this.$bvModal.show('modal-question-edit')
    },
    Checkeditem(data) {
      const obj = {
        // eslint-disable-next-line no-underscore-dangle
        id: data._id,
        question: data.question,
        result: data.answer,
        active: data.active,
      }
      this.$http.post('/Question/Store/Update', obj)
        .then(() => {
          this.Success('บันทึกข้อมูลเรียบร้อย')
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.SwalError(err)
        })
    },
    Deleteitem(data) {
      this.$bvModal.msgBoxConfirm('ต้องการลบข้อมูลหรือไม่ ?', {
        title: 'ยืนยันข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          const obj = {
            // eslint-disable-next-line no-underscore-dangle
            id: data._id,
          }
          this.$http.post('Question/Delete/data', obj)
            .then(() => {
              this.Success('ลบข้อมูลเรียบร้อย')
              this.getData()
            })
            .catch(err => {
              console.log(err)
              this.SwalError(err)
            })
        }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
